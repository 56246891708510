import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <body className="index-page">
      <header
        id="header"
        className="header d-flex align-items-center sticky-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo d-flex align-items-center">
            <img src={`${process.env.PUBLIC_URL}/img/logo1.png`} />
          </a>

          <nav
            id="navmenuc:\Program Files\Microsoft VS Code\resources\app\out\vs\code\electron-browser\workbench\workbench.html"
            className="navmenu"
          >
            <ul>
              <li>
                <a href="#hero" className="active">
                  Home
                </a>
              </li>
               
              <li>
                <a href="#services">Services</a>
              </li>
             
              
              
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main className="main">
        <section id="hero" class="hero section light-background">
          <div class="container position-relative">
            <div class="row gy-5">
              <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2>Business Builder</h2>
                <p>Transform Your Business to a Technology-Driven Enterprise</p>
                <p>
                  n today's fast-paced digital age, transforming your business
                  to be technology-driven is no longer a luxury but a necessity.
                  Embrace innovation and witness a significant increase in
                  profitability and operational efficiency Customer Relationship
                  Management (CRM) software is essential for any business
                  looking to improve their customer interactions and drive
                  sales.
                </p>
                <div class="d-flex">
                  <a href="#about" class="btn-get-started">
                    Get Started
                  </a>
                  <a
                    // href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                    href="#"
                    class="glightbox btn-watch-video d-flex align-items-center"
                  >
                    <i class="bi bi-play-circle"></i>
                    <span>Watch Video</span>
                  </a>
                </div>
              </div>
              <div class="col-lg-6 order-1 order-lg-2">
                <img src="/img/hero-img.png" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div class="icon-boxes position-relative">
            <div class="container position-relative">
              <div class="row gy-4 mt-5">
                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-easel"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                       CRM
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-gem"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                        HR MODULE
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                       PROJECT MANAGEMENT
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-command"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                        ANALYTICS
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" class="services section">
          <div class="container section-title">
            <h2>Services</h2>
            <div>
              <span>What Problem We</span>{' '}
              <span class="description-title">Solve</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4 col-md-6">
                <div class="service-item  position-relative">
                  <div class="icon">
                    <i class="bi bi-activity"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Missed Follow Up</h3>
                  </a>
                  <p>
                    Provident nihil minus qui consequatur non omnis maiores. Eos
                    accusantium minus dolores iure perferendis tempore et
                    consequatur.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-broadcast"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Eosle Commodi</h3>
                  </a>
                  <p>
                    Ut autem aut autem non a. Sint sint sit facilis nam iusto
                    sint. Libero corrupti neque eum hic non ut nesciunt dolorem.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-easel"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Ledo Markt</h3>
                  </a>
                  <p>
                    Ut excepturi voluptatem nisi sed. Quidem fuga consequatur.
                    Minus ea aut. Vel qui id voluptas adipisci eos earum
                    corrupti.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-bounding-box-circles"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Asperiores Commodit</h3>
                  </a>
                  <p>
                    Non et temporibus minus omnis sed dolor esse consequatur.
                    Cupiditate sed error ea fuga sit provident adipisci neque.
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-calendar4-week"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Velit Doloremque</h3>
                  </a>
                  <p>
                    Cumque et suscipit saepe. Est maiores autem enim facilis ut
                    aut ipsam corporis aut. Sed animi at autem alias eius
                    labore.
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-chat-square-text"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Dolori Architecto</h3>
                  </a>
                  <p>
                    Hic molestias ea quibusdam eos. Fugiat enim doloremque aut
                    neque non et debitis iure. Corrupti recusandae ducimus enim.
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" class="features section light-background">
          <div class="container section-title">
            <h2>Features</h2>
            <div>
              <span>Check Our</span>{' '}
              <span class="description-title">Features</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-5 justify-content-between">
              <div class="col-xl-5">
                <img src="/img/features.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-6 d-flex">
                <div class="row align-self-center gy-4">
                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Reminder</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Client Follow System</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Call Performance</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Reporting</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Staff Management</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Location Tracking</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" class="faq section light-background">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4">
                <div class="content px-xl-5">
                  <h3>
                    <span>Frequently Asked </span>
                    <strong>Questions</strong>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Duis aute irure dolor in reprehenderit
                  </p>
                </div>
              </div>

              <div class="col-lg-8">
                <div class="faq-container">
                  <div class="faq-item faq-active">
                    <h3>
                      <span class="num">1.</span>{' '}
                      <span>
                        Non consectetur a erat nam at lectus urna duis?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                        Feugiat pretium nibh ipsum consequat. Tempus iaculis
                        urna id volutpat lacus laoreet non curabitur gravida.
                        Venenatis lectus magna fringilla urna porttitor rhoncus
                        dolor purus non.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">2.</span>{' '}
                      <span>
                        Feugiat scelerisque varius morbi enim nunc faucibus a
                        pellentesque?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">3.</span>{' '}
                      <span>
                        Dolor sit amet consectetur adipiscing elit pellentesque?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                        Eleifend mi in nulla posuere sollicitudin aliquam
                        ultrices sagittis orci. Faucibus pulvinar elementum
                        integer enim. Sem nulla pharetra diam sit amet nisl
                        suscipit. Rutrum tellus pellentesque eu tincidunt.
                        Lectus urna duis convallis convallis tellus. Urna
                        molestie at elementum eu facilisis sed odio morbi quis
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">4.</span>{' '}
                      <span>
                        Ac odio tempor orci dapibus. Aliquam eleifend mi in
                        nulla?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">5.</span>{' '}
                      <span>
                        Tempus quam pellentesque nec nam aliquam sem et tortor
                        consequat?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                        Molestie a iaculis at erat pellentesque adipiscing
                        commodo. Dignissim suspendisse in est ante in. Nunc vel
                        risus commodo viverra maecenas accumsan. Sit amet nisl
                        suscipit adipiscing bibendum est. Purus gravida quis
                        blandit turpis cursus in
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact section">
          <div class="container section-title">
            <h2>Contact</h2>
            <div>
              <span>Need Help?</span>{' '}
              <span class="description-title">Contact Us</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4">
                <div class="info-item d-flex">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Address</h3>
                    <p>2 nd Floor, Hilite Business Park Calicut</p>
                  </div>
                </div>

                <div class="info-item d-flex">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>+1 </p>
                  </div>
                </div>

                <div class="info-item d-flex">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>info@businessbuilder.com</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <form
                  action="forms/contact.php"
                  method="post"
                  class="php-email-form"
                >
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>

                    <div class="col-md-6 ">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>

                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control"
                        name="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>

                    <div class="col-md-12">
                      <textarea
                        class="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </div>

                    <div class="col-md-12 text-center">
                      <div class="loading">Loading</div>
                      <div class="error-message"></div>
                      <div class="sent-message">
                        Your message has been sent. Thank you!
                      </div>

                      <button type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </body>
  );
}

export default App;
